<template>
  <div class="main-container">
    <!--查询区域start-->
    <div class="search-area">
      <div class="form-area">
        <el-form class="search-form" ref="search-form" :model="searchForm" label-width="100px">
          <el-form-item label="系统模块">
            <el-input v-model="searchForm.moduleName"></el-input>
          </el-form-item>
          <el-form-item label="登录账号">
            <el-input v-model="searchForm.userName"></el-input>
          </el-form-item>
          <el-form-item label="用户IP">
            <el-input v-model="searchForm.userIp"></el-input>
          </el-form-item>
          <el-form-item label="事件结果">
            <el-select placeholder="请选择" v-model="searchForm.invokeResult" clearable>
              <el-option v-for="item in opStatusList" :key="item.value" :label="item.label" :value="item.value"> </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="事件类型">
            <el-select placeholder="请选择" v-model="searchForm.eventType" clearable>
              <el-option v-for="item in eventTypeList" :key="item.value" :label="item.label" :value="item.value"> </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="操作类型">
            <el-select placeholder="请选择" v-model="searchForm.operationType" clearable>
              <el-option v-for="item in opTypeList" :key="item.value" :label="item.label" :value="item.value"> </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="操作时间">
            <el-date-picker v-model="searchForm.date" type="datetimerange" range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期" align="right" value-format="yyyy-MM-dd HH:mm:ss"> </el-date-picker>
          </el-form-item>
          <el-form-item label="排序规则">
            <el-select placeholder="请选择" v-model="searchForm.collationRules" clearable>
              <el-option v-for="item in querySortList" :key="item.value" :label="item.label" :value="item.value"> </el-option>
            </el-select>
          </el-form-item>
        </el-form>
      </div>
      <div class="search-btn-area">
        <el-button type="primary" class="search-btn" @click="queryUserList">查询</el-button>
        <el-button class="reset-btn" @click="resetBtn">重置</el-button>
      </div>
    </div>
    <!--查询区域end-->

    <!-- 表格区域start -->
    <div class="table-area">
      <div class="operate-btn">
        <el-button type="text" style="padding: 5px" @click="resume" icon="el-icon-refresh-left">恢复</el-button>
        <el-button type="text" style="padding: 5px" @click="backUp" icon="el-icon-copy-document" :loading="backUpLoading">备份</el-button>
        <el-button type="text" style="padding: 5px" @click="exportData" icon="el-icon-upload2" :loading="exportLoading">导出</el-button>
      </div>
      <el-table border :data="tableData" type="mini" v-loading="tableLoading">
        <el-table-column prop="userName" label="登录账号" width="140"> </el-table-column>
        <el-table-column prop="userIp" label="用户IP" width="140"> </el-table-column>
        <el-table-column prop="eventType" label="事件类型" width="110" :formatter="eventTypeFormat"> </el-table-column>
        <el-table-column prop="operationInfo" label="事件描述" min-width="300" show-overflow-tooltip> </el-table-column>
        <el-table-column prop="status" label="事件结果" width="110">
          <template slot-scope="scope">
            <span>{{ scope.row.invokeResult === '1' ? '调用成功' : '调用失败' }}</span>
          </template>
        </el-table-column>
        <el-table-column prop="opTypeName" label="操作类型" width="110" :formatter="operationInfoFormat"> </el-table-column>
        <el-table-column prop="operationStartTime" label="操作时间" width="180"> </el-table-column>
        <el-table-column prop="moduleName" label="相关模块" width="110"> </el-table-column>
        <el-table-column label="响应时间" width="120">
          <template slot-scope="scope">
            <span :title="scope.row.reponseTime">{{ scope.row.reponseTime }}</span>
          </template>
        </el-table-column>
      </el-table>
      <div class="pagination-area">
        <el-pagination background @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page="paginationConfig.pageNum" :page-sizes="[10, 20, 50, 100]" :page-size="paginationConfig.pageSize" layout="total, sizes, prev, pager, next, jumper" :total="paginationConfig.total"> </el-pagination>
      </div>
    </div>
    <!-- 表格区域end -->
  </div>
</template>

<script>
import { getOperateLogPagination, backupOperateLog, resumeOperateLog } from '@/service/api/audit/log'
export default {
  name: 'OperateLog',
  data() {
    return {
      searchForm: {},
      // 排序规则列表
      querySortList: [
        {
          value: '1',
          label: '账号正序'
        },
        {
          value: '2',
          label: '账号倒序'
        },
        {
          value: '3',
          label: '事件类型正序'
        },
        {
          value: '4',
          label: '事件类型倒序'
        },
        {
          value: '5',
          label: '操作时间正序'
        },
        {
          value: '6',
          label: '操作时间倒序'
        }
      ],
      opStatusList: [
        {
          value: 1,
          label: '成功'
        },
        {
          value: 0,
          label: '失败'
        }
      ],
      eventTypeList: [
        {
          value: 1,
          label: '系统事件'
        },
        {
          value: 2,
          label: '业务事件'
        }
      ],
      opTypeList: [
        {
          value: 1,
          label: '查询'
        },
        {
          value: 2,
          label: '新增'
        },
        {
          value: 3,
          label: '修改'
        },
        {
          value: 4,
          label: '删除'
        },
        {
          value: 5,
          label: '越权'
        },
        {
          value: 6,
          label: '导出'
        },
        {
          value: 7,
          label: '备份'
        },
        {
          value: 8,
          label: '恢复'
        },
        {
          value: 9,
          label: '容量告警'
        }
      ],
      tableData: [],
      // 分页数据
      paginationConfig: {
        pageNum: 1,
        pageSize: 10,
        total: 0
      },
      backUpLoading: false,
      exportLoading: false,
      tableLoading: false
    }
  },
  created() {
    this.getList()
  },
  methods: {
    resetBtn() {
      this.searchForm = {}
      this.getList()
    },
    queryUserList() {
      this.paginationConfig.pageNum = 1
      this.getList()
    },
    getList() {
      const data = Object.assign({}, this.searchForm, {
        pageNum: this.paginationConfig.pageNum,
        pageSize: this.paginationConfig.pageSize,
        startTime: this.searchForm.date && this.searchForm.date[0],
        endTime: this.searchForm.date && this.searchForm.date[1]
      })
      delete data.date
      this.tableLoading = true
      getOperateLogPagination(data)
        .then(res => {
          if (res.data.status === 200) {
            this.tableData = res.data.data
            this.paginationConfig.total = Number(res.data.total)
          }
          this.tableLoading = false
        })
        .catch(() => {
          this.$message.error('服务器错误')
          this.tableLoading = false
        })
    },
    // 获取操作类型
    operationInfoFormat(row) {
      return row.operationType && this.opTypeList.find(item => item.value.toString() === row.operationType).label
    },
    // 获取事件类型
    eventTypeFormat(row) {
      return row.eventType && this.eventTypeList.find(item => item.value.toString() === row.eventType).label
    },
    /**
     * 分页事件
     */
    // 页大小切换
    handleSizeChange(val) {
      this.paginationConfig.pageNum = 1
      this.paginationConfig.pageSize = val
      this.getList()
    },
    // 页码变更
    handleCurrentChange(val) {
      this.paginationConfig.pageNum = val
      this.getList()
    },
    resume() {
      const msgTip = this.$message({
        customClass: 'msg-tip',
        offset: 50,
        center: true,
        iconClass: 'el-icon-loading',
        duration: 0,
        message: '正在恢复中...'
      })
      resumeOperateLog().then(res => {
        if (res.data.status === 200) {
          msgTip.close()
          this.$message.success(res.data.msg)
        }
      })
    },
    backUp() {
      const msgTip = this.$message({
        customClass: 'msg-tip',
        offset: 50,
        center: true,
        iconClass: 'el-icon-loading',
        duration: 0,
        message: '正在备份中...'
      })
      backupOperateLog().then(res => {
        if (res.data.status === 200) {
          msgTip.close()
          this.$message.success(res.data.msg)
        }
      })
    },
    exportData() {}
  }
}
</script>

<style scoped lang="scss">
@import '~$assets/css/common/search-area.scss';
@import '~$assets/css/common/table-area.scss';
.main-container {
  width: 100%;
  box-sizing: border-box;
  .search-area {
    margin: 0;
    padding: 0;
  }
  .table-area {
    margin: 0;
    padding: 0;
  }
  .pagination-area {
    display: flex;
    justify-content: flex-end;
  }
}
</style>

<style lang="scss">
.el-tooltip__popper {
  width: 40%;
}
.msg-tip {
  background-color: #fdf6ec;
  border-color: #faecd8;
  color: #e6a23c;
  i {
    margin-right: 10px;
  }
}
</style>
